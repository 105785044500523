import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [7,2];

export const dictionary = {
		"/(marketing)/(with-header)": [28,[7,9]],
		"/(marketing)/(with-header)/about": [29,[7,9]],
		"/(admin)/brand/[teamSlug]/(menu)": [~11,[2,3]],
		"/(admin)/brand/[teamSlug]/api": [18,[2]],
		"/(admin)/brand/[teamSlug]/(menu)/competitions/[id]": [~12,[2,3]],
		"/(admin)/brand/[teamSlug]/competitions/[id]/edit": [~19,[2]],
		"/(admin)/brand/[teamSlug]/(menu)/settings": [13,[2,3,4]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/billing": [~14,[2,3,4]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/integrations": [15,[2,3,4]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/members": [~16,[2,3,4]],
		"/(admin)/brand/[teamSlug]/(menu)/users": [~17,[2,3]],
		"/(marketing)/(with-header)/customers": [30,[7,9]],
		"/(giveaway)/c/[slug]": [~21,[6]],
		"/(giveaway)/c/[slug]/embed": [~22,[6]],
		"/(giveaway)/demo/[slug]": [~23],
		"/(marketing)/features": [38,[7]],
		"/(marketing)/(auth)/forgot-password": [24,[7,8]],
		"/(marketing)/(with-header)/guides": [~31,[7,9]],
		"/(marketing)/(with-header)/guides/[slug]": [~32,[7,9]],
		"/(marketing)/(with-header)/integrations": [33,[7,9]],
		"/(marketing)/invite/[id]": [~39,[7]],
		"/(admin)/onboarding": [20,[2,5]],
		"/(marketing)/(with-header)/pricing": [34,[7,9]],
		"/(marketing)/(with-header)/privacy": [35,[7,9]],
		"/(marketing)/(auth)/sign-in": [25,[7,8]],
		"/sign-out": [~40],
		"/(marketing)/(auth)/sign-up": [26,[7,8]],
		"/(marketing)/(with-header)/support": [36,[7,9]],
		"/(marketing)/(with-header)/terms": [37,[7,9]],
		"/(marketing)/(auth)/update-password": [27,[7,8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';