<script>
  import "../../../app.css"
  import Header from "$lib/components/Header.svelte"
  import Footer from "$lib/components/Footer.svelte"

  export let data
</script>

<div
  class="flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip"
>
  <Header />

  <main class="grow">
    <slot />
  </main>
  <Footer />
</div>
