<script>
  const footerItems = {
    blocks: [
      {
        title: "Product",
        links: [
          // { name: "Features", href: "/features" },
          // { name: "Integrations", href: "/integrations" },
          { name: "Pricing & Plans", href: "/pricing" },
          // { name: "Changelog", href: "#" },
        ],
      },
      // {
      //   title: "Company",
      //   links: [
      //     { name: "About us", href: "/about" },
      //     // { name: "Blog", href: "/blog" },
      //     // { name: "Careers", href: "#" },
      //   ],
      // },
      {
        title: "Resources",
        links: [
          {
            name: "Affiliate Program",
            href: "https://promo-amp.getrewardful.com/signup",
          },
          { name: "Privacy Policy", href: "/privacy" },
          { name: "Terms of use", href: "/terms" },
        ],
      },
      {
        title: "Contact Us",
        links: [
          { name: "hi@promoamp.com", href: "mailto:hi@promoamp.com" },
          // { name: "support@promoamp.com", href: "mailto:support@promoamp.com" },
          // { name: "Status", href: "#" },
        ],
      },
      // {
      //   title: "Social",
      //   links: [
      //     { name: "Twitter", href: "#", ariaLabel: "Twitter" },
      //     { name: "Medium", href: "#", ariaLabel: "Medium" },
      //     { name: "Github", href: "#", ariaLabel: "Github" },
      //   ],
      // },
    ],
    branding: {
      logo: {
        src: "/logo.svg",
        alt: "Promo Amp Home Page",
        width: 160,
        href: "/",
      },
      copyright: `&copy; ${new Date().getFullYear()} PromoAmp.com - All rights reserved.`,
    },
  }
</script>

<footer>
  <div class="max-w-6xl mx-auto px-4 sm:px-6">
    <!-- Top area: Blocks -->
    <div class="grid sm:grid-cols-12 gap-10 py-8 md:py-12">
      <!-- Branding block -->
      <div class="sm:col-span-12 lg:col-span-4 space-y-2">
        <div>
          <!-- Logo -->
          <a
            class="inline-flex"
            href={footerItems.branding.logo.href}
            aria-label={footerItems.branding.logo.alt}
          >
            <img
              src={footerItems.branding.logo.src}
              alt={footerItems.branding.logo.alt}
              width={footerItems.branding.logo.width}
            />
          </a>
        </div>
        <div class="text-sm text-gray-600">
          {@html footerItems.branding.copyright}
        </div>
      </div>

      <!-- Dynamic blocks -->
      {#each footerItems.blocks as block}
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-2 space-y-2">
          <h3 class="text-sm font-medium">{block.title}</h3>
          <ul class="text-sm space-y-2">
            {#each block.links as link}
              <li>
                <a
                  class="text-gray-600 hover:text-gray-900 transition"
                  href={link.href}
                  aria-label={link.ariaLabel || link.name}
                >
                  {link.name}
                </a>
              </li>
            {/each}
          </ul>
        </div>
      {/each}
    </div>
  </div>
</footer>
